<template>
  <b-container>
    <b-row class="justify-content-md-left mt-5">
      <b-col>
        <h3 class="mt-2 text-primary">Research Methodology</h3>
        <h5 class="mb-5 text-secondary">Tech Scaelup Reports</h5>

        <p dir="ltr" style="line-height: 1.38; text-align: left; margin-top: 0pt; margin-bottom: 9pt;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Mind the Bridge categorizes&nbsp;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Tech Companies&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;as follows:</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; text-align: left; margin-top: 0pt; margin-bottom: 9pt; padding: 0pt 0pt 0pt 13pt;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Startup&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;&lt;$1M funding raised</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; text-align: left; margin-top: 0pt; margin-bottom: 9pt; padding: 0pt 0pt 0pt 13pt;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaleup&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;&gt;$1M funding raised</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; text-align: left; margin-top: 0pt; margin-bottom: 9pt; padding: 0pt 0pt 0pt 13pt;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaler&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;&gt;$100M funding raised</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; text-align: left; margin-top: 0pt; margin-bottom: 9pt; padding: 0pt 0pt 0pt 13pt;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Super Scaler&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;&gt;$1B funding raised</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; text-align: left; margin-top: 0pt; margin-bottom: 9pt; padding: 0pt 0pt 0pt 13pt;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Dual Companies&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Startups founded in one country that relocated their headquarters &ndash; and with that part of their value chain &ndash; abroad, while maintaining a strong operational presence in their country of origin.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Mind the Bridge defines&nbsp;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Tech Companies&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;as companies:</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >operating in Tech &amp; Digital industries,</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >founded in the New Millennium,</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >with at least one funding event since 2010.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Biotech, Life Sciences and Pharma, Semiconductors are currently not included.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Categorization of &ldquo;Tech Companies&rdquo; is based on capital raised (including both capital raised through private equity and on the stock markets), not on valuation .</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Mind the Bridge categorizes funding as follows:</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Equity Funding&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >It includes:</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >All private equity funding rounds (including angel investments, seed capital, series A, B, C, etc...), either coming from VCs and CVCs; funding raised on equity crowdfunding platforms; convertible notes and other equity-based financial
            instruments.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Public funding provided in exchange for equity (e.g. specific investments vehicles from the EIB).</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >IPO proceeds, at closing price, including over-subscribed shares.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Capital raised through ICO (exchange rate of cryptos at the day of ICO).</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-left: 14pt; text-indent: -13pt; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 13pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Operations with no new cash entering the company&apos;s balance sheet as a number of existing shareholders sell all or a portion of their holding are not considered. This includes e.g. secondary funding rounds, buyouts and buy-ins.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Non-Equity Funding&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Includes (but not limited to): public grants, debt financing, product crowdfunding.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Mind the Bridge produces and monitors the following indicators:</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaleup Density Ratio&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Number of scaleups per 100K inhabitants. A measure of density of scaleups in a given ecosystem.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaleup Investing Ratio&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Capital raised by Scaleups as a percentage of GDP. A measure meant to measure the capital invested in scaleups in a given ecosystem, compared to the size of the overall economy of that country.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaleup Country Index&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Country ranking built upon Scaleup Density Ratio and Scaleup Investing Ratio. A measure of the overall innovation commitment of a given ecosystem and its ability to produce significant tech players.&nbsp;</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaleup (City) Hub Index</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Hub ranking built upon Scaleup Density Ratio and Scaleup Investing Ratio. A measure of the overall innovation commitment of a given city/tech hub and its ability to produce significant tech players.&nbsp;</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Scaleup Matrix&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The matrix visually compares ecosystems by factoring the Scaleup Density Ratio and Scaleup Investing Ratios.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Closure&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We consider &ldquo;closed&rdquo; all scaleups that shut down and do not continue to operate anymore.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Reasons for closure include (but are not limited to):</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >- non-voluntary closure</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >e.g. bankruptcy</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >- voluntary closure</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Exit&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Liquidity event that occurred since 2010.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;M&amp;A (Merger &amp; Acquisition)&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >For companies that exited via M&amp;A, the valuation is the amount that the company got acquired for.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;IPO (Initial Public Offering)&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >For companies that went public, the exit valuation is that on the day of the IPO.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Enterprise Value&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >A measure of the company&rsquo;s total current value, including its market capitalization plus or minus any short/long-term debt and net liquidity. It defines the theoretical takeover price if a company were to be bought.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;ICO (Initial Coin Offering)&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >A means of raising capital using cryptocurrencies issued by the company (&rdquo;tokens&rdquo;) in exchange for legal tender or other cryptocurrencies such as Bitcoin or Ethereum. Price data converted in US$ at day of sale. Data collected
            from ICOBench, ICOHolder, Tokendata.io, Coinmarketcap.com and other sources including official company documents.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;GDP (Gross Domestic Product)</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data from World Bank (2019, PPP).</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Population&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data from the World Bank, United Nations, Local government and other reliable sources (most recent census data).</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Geographies</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&rdquo;:</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Europe&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We analyze scaleups headquartered in 45 Continental European states as listed below. We categorize European sub-regions as follows:</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >British Isles: United Kingdom (including Gibraltar, Guernsey and Jersey), Ireland</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Central Europe: France, Germany, Switzerland, Austria, Principate of Monaco, Liechtenstein.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Nordics: Denmark, Iceland, Finland, Sweden, Norway.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Southern Europe: Spain, Italy, Portugal, Greece, Malta, Cyprus, Andorra, San Marino, Vatican City.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Benelux: The Netherlands, Belgium, Luxembourg.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Eastern Europe: Poland, Czech Republic, Slovakia, Slovenia, Croatia, Serbia, Bosnia and</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Herzegovina, Montenegro, Macedonia, Kosovo, Albania, Romania, Bulgaria, Hungary, Moldova, Ukraine, Belarus.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Baltics: Estonia, Lithuania, Latvia.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;MENA&rdquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br /></span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Scaleups headquartered in 19 countries identified based on World Bank definition, excluding Malta (included in Continental Europe due to its participation in the Eurozone), Israel, and Turkey (analyzed separately for international
            comparability purposes).</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We categorize MENA sub-regions as follows:</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Middle-East: United Arab Emirates, Kingdom of Saudi Arabia (KSA), Kuwait, Qatar, Bahrain, Sultanate of Oman, Yemen, Kingdom of Jordan, Iraq, Islamic Republic of Iran, Syria, Lebanon, West Bank and Gaza, Djibouti.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >North Africa: Egypt, Morocco, Algeria, Tunisia, Libya.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;United States of America&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Scaleups headquartered in all 50 US states (Overseas territories (e.g. Guam) are not included).</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data collected with the support of Crunchbase and analyzed and reclassified by Mind the Bridge.</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We also separately analyze the Silicon Valley ecosystem. Refer to &ldquo;Mind the Bridge,</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;European Innovation Economy in Silicon Valley</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >, September 2018&rdquo; for further details about the methodology.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;China&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Scaleups headquartered in the People&rsquo;s Republic of China (including the special administrative regions of Hong Kong and Macau).</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data collected with the support of Zero2IPO and reclassified by Mind the Bridge.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Israel&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Scaleups headquartered in Israel.&nbsp;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data collected with the support of Crunchbase and StartupNation and reclassified by Mind the Bridge.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;South Korea&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Scaleups headquartered in South Korea.&nbsp;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data collected with the support of Crunchbase, TheVC.kr, Startup Alliance Korea and reclassified by Mind the Bridge.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">India</span
          ><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Scaleups headquartered in India.&nbsp;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data collected with the support of Venture Intelligence and reclassified by Mind the Bridge.</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;Silicon Valley&rdquo;</span
          >
        </p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The following 46 cities are home to various high-tech companies and have thereby become associated with “Silicon Valley”, although some are technically outside of Silicon Valley: Alameda, Albany, Atherton, Belmont, Berkeley, Brisbane, Burlingame, Campbell, Castro Valley, Cupertino, Daly City, Dublin, East Palo Alto, Emeryville, Foster City, Fremont, Hayward, Los Altos, Los Altos Hills, Los Gatos, Menlo Park, Millbrae, Milpitas, Monte Sereno, Morgan Hill, Mountain View, Newark, Oakland, Palo Alto, Pleasanton, Portola Valley, Redwood City, Redwood Shores, San Bruno, San Carlos, San Francisco, San Jose, San Leandro, San Mateo, San Ramon, Santa Clara, Saratoga, South San Francisco, Stanford, Sunnyvale, Union City. All aggregate figures regarding “Silicon Valley” (e.g. population, GDP) account only for the aforementioned cities.&nbsp;</span
          >
        </p>
        <p style="text-align: left;"><br /></p>
        <p style="text-align: left;"><br /></p>
        <p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;">
          <span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Research is ongoing and results reported in MTB Research reports are subject to continuous updates and systematic review.</span
          >
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "signin",
  data() {
    return {};
  },
};
</script>
